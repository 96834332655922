import React from 'react';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { MdArrowBack } from 'react-icons/md';
import get from 'lodash/get';
import Image from 'next/image';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Router from 'next/router';

import Footer from 'components/organisms/Footer';
import AContainer1440 from 'components/atoms/container/AContainer1440';

const NotFound = () => {
  const { t } = useTranslation('common');

  const submitError = () => {
    try {
      throw new Error(`ERROR: 404 Page - The user has accessed this page: ${get(window, 'location.href', '')}`);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // Opinionated: do not record an exception in Sentry for 404
  return (
    <Box>
      <Box className="o-shadown2 o-onboarding-menu">
        <AContainer1440>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Link href="/">
                <img src="/assets/logo.png" alt="Urban CV" width={110} className="o-onboarding-menu__img" />
              </Link>
            </Box>
          </Toolbar>
        </AContainer1440>
      </Box>
      <Box margin="auto" maxWidth={1200} pt={15}>
        <Button onClick={() => Router.back()}>
          <MdArrowBack color="#478DF2" size={20} />
          <Box component="span" color="#478DF2" className="o-button-medium" ml={2}>
            {t('action.back')}
          </Box>
        </Button>
      </Box>

      <Box className="u-flex-center" flexDirection="column" minHeight={500} height="calc(100vh - 164px)">
        <Image src="/assets/svg/404_picture.svg" alt="9cv9" width={240} height={312} />
        <Box component="h3" color="#212121" mt={14}>
          {t('common.errorNotFound')}
        </Box>
        <Box onClick={submitError} className="u-pointer" display="none">
          Submit error
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default NotFound;
